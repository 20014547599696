import { useContext } from 'react'
import { ThemeContext } from '../theme/ThemeContext'
import { Link } from 'react-router-dom'
import { useAllPrismicDocumentsByType } from '@prismicio/react'
import { formatDate } from '../libs/formatDate'
import LoadingSpinner from '../components/Spinner/LoadingSpinner'
import styles from '../styles/Blogs.module.css'
import '../index.css'

const Blog = () => {
  const [blogPosts] = useAllPrismicDocumentsByType('post')
  const theme = useContext(ThemeContext)
  const darkMode = theme.state.darkMode

  if (!blogPosts) {
    return <LoadingSpinner />
  }

  const sorted = blogPosts.sort((a, b) => a.uid - b.uid).reverse()

  return (
    <div className={styles['blogs-wrapper']}>
      <section className={styles['columns-wrapper']}>
        <p className={`${darkMode ? 'num-header-dark' : 'num-header-light'}`}>
          Number
        </p>
        <p className={`${darkMode ? 'para-dark' : 'para-light'}`}>Title</p>
        <p className={`${darkMode ? 'para-dark' : 'para-light'}`}>Date</p>
      </section>

      {sorted.map((post) => (
        <div
          key={post.id}
          className={`${darkMode ? 'para-dark' : 'para-light'}`}
        >
          <ul className={styles['blog-list-wrapper']}>
            <li className={styles['num-column']}>
              <Link
                key={post.uid}
                to={`/blog/${post.uid}`}
                className={styles['num-link']}
              >
                <span className={`${darkMode ? 'para-dark' : 'para-light'}`}>
                  {post.uid}
                </span>
              </Link>
            </li>

            <li className={styles['blog-column']}>
              <Link
                key={post.uid}
                to={`/blog/${post.uid}`}
                className={styles['blog-link']}
              >
                <span className={`${darkMode ? 'para-dark' : 'para-light'}`}>
                  {post.data.title[0].text}
                </span>
              </Link>
            </li>

            <li className={styles['date-column']}>
              <span className={`${darkMode ? 'para-dark' : 'para-light'}`}>
                {formatDate(post.data.date)}
              </span>
            </li>
          </ul>
        </div>
      ))}
    </div>
  )
}

export default Blog
