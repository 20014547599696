import * as prismic from '@prismicio/client'

export const repositoryName = 'fullstack-blog'

export const client = prismic.createClient(repositoryName, {
  accessToken: process.env.REACT_APP_PRISMIC_API_KEY,
  routes: [
    {
      type: 'blog_home',
      path: '/blogs',
    },
    {
      type: 'blog_home',
      path: '/',
    },
    {
      type: 'post',
      path: '/blog/:uid',
    },
  ],
})
