import { useContext } from 'react'
import { Link } from 'react-router-dom'
import ToggleTheme from '../theme/ToggleTheme'
import { ThemeContext } from '../theme/ThemeContext'
import '../index.css'
import styles from '../styles/Nav.module.css'

const Nav = () => {
  const theme = useContext(ThemeContext)
  const darkMode = theme.state.darkMode

  return (
    <nav className={styles.nav}>
      <div className={styles.toggleThemeButton}>
        <li className={styles['theme-button']}>
          <ToggleTheme />
        </li>
      </div>
      <ul className={styles.links}>
        <li className={styles.link}>
          <Link to='/' className={styles.link}>
            <p className={`${darkMode ? 'para-dark' : 'para-light'}`}>Home</p>
          </Link>
        </li>
        <li className={styles.link}>
          <Link to='/blogs' className={styles.link}>
            <p className={`${darkMode ? 'para-dark' : 'para-light'}`}>Blogs</p>
          </Link>
        </li>

        <li className={styles.link}>
          <a className={styles.link} href='/'>
            <p className={`${darkMode ? 'para-dark' : 'para-light'}`}>Videos</p>
          </a>
        </li>
        {/* <li>
          <Link to='/' className={styles.link}>
            <p
              className={`fullstackog ${darkMode ? 'para-dark' : 'para-light'}`}
            >
              FullstackOg
            </p>
          </Link>
        </li> */}
      </ul>
    </nav>
  )
}

export default Nav
