import styles from './spinner.module.css'

function LoadingSpinner() {
  return (
    <div className={styles['spinner-container']}>
      <div className={styles['loading-spinner']}></div>
    </div>
  )
}

export default LoadingSpinner
