import { useContext } from 'react'
import { ThemeContext } from '../theme/ThemeContext'
import { useSinglePrismicDocument } from '@prismicio/react'
import LoadingSpinner from '../components/Spinner/LoadingSpinner'

import styles from '../styles/Home.module.css'
import '../index.css'

const Home = () => {
  const [homeDetails] = useSinglePrismicDocument('blog_home')
  const theme = useContext(ThemeContext)
  const darkMode = theme.state.darkMode

  if (homeDetails) {
    return (
      <>
        {homeDetails.data.headline.map((headline, index) => {
          return (
            <div
              key={index}
              className={`${darkMode ? 'para-dark' : 'para-light'}`}
            >
              <section className={styles['headline-wrapper']}>
                <h1 className={styles.headline}>{headline.text}</h1>
              </section>

              <div className={styles.parent}>
                <div className={styles.paragraph}>
                  <p className={styles.about}>
                    {homeDetails.data.about[0].text}
                  </p>
                </div>
                {/* <div className={styles['photo-wrapper']}>
                  <img
                    className={styles.photo}
                    src={homeDetails.data.image.url}
                    alt='An Engineer'
                  />
                </div> */}
              </div>
            </div>
          )
        })}
      </>
    )
  }

  return <LoadingSpinner />
}

export default Home
