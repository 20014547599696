import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { ThemeContext } from '../theme/ThemeContext'
import { usePrismicDocumentByUID } from '@prismicio/react'
import LoadingSpinner from '../components/Spinner/LoadingSpinner'
import '../index.css'
import styles from '../styles/BlogPost.module.css'

const BlogPost = () => {
  const { uid } = useParams()
  const [blog] = usePrismicDocumentByUID('post', uid)
  const theme = useContext(ThemeContext)
  const darkMode = theme.state.darkMode

  if (blog) {
    return (
      <div className={styles['blog-wrapper']}>
        <section className={styles['blog-header']}>
          <h3
            className={`${darkMode ? 'blog-title-dark' : 'blog-title-light'}`}
          >
            {blog.data.title[0].text}
          </h3>
          <h3 className={`${darkMode ? 'blog-date-dark' : 'blog-date-light'}`}>
            {blog.data.date.slice(5, 10) +
              '-' +
              blog.data.date.split('-').shift()}
          </h3>
        </section>

        {blog.data.body.map((details, index) => {
          if (details.primary.text) {
            return (
              <div key={index} className={styles['blog-text-wrapper']}>
                {details.primary.text.map((paragraph, index) => (
                  <p
                    key={index}
                    className={`para ${darkMode ? 'para-dark' : 'para-light'}`}
                  >
                    {paragraph.text}
                  </p>
                ))}
              </div>
            )
          } else if (details.primary.image) {
            return (
              <div key={index} className={styles['image-wrapper']}>
                <img
                  className={styles['blog-image']}
                  src={details.primary.image.url}
                  alt={details.primary.image.alt}
                />
                <p
                  className={`image-caption ${
                    darkMode ? 'para-dark' : 'para-light'
                  }`}
                >
                  {details.primary.caption[0].text}
                </p>
              </div>
            )
          }
          return null
        })}
      </div>
    )
  } else {
    return <LoadingSpinner />
  }
}

export default BlogPost
