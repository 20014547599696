import { useContext } from 'react'
import { ThemeContext } from '../theme/ThemeContext'
import styles from '../styles/Footer.module.css'
import '../index.css'
import { FaGithub } from 'react-icons/fa'
import { BsLinkedin, BsTwitter } from 'react-icons/bs'

const Footer = () => {
  const theme = useContext(ThemeContext)
  const darkMode = theme.state.darkMode

  return (
    <div className={styles.footer}>
      <section className={styles['icons-wrapper']}>
        <a
          className={`${
            darkMode ? 'dark-social-media-icons' : 'light-social-media-icons'
          }`}
          href='https://github.com/luisocasio'
        >
          <FaGithub />
        </a>
        <a
          className={`${
            darkMode ? 'dark-social-media-icons' : 'light-social-media-icons'
          }`}
          href='https://www.linkedin.com/in/ocasio-perez/'
        >
          <BsLinkedin />
        </a>
        <a
          className={`${
            darkMode ? 'dark-social-media-icons' : 'light-social-media-icons'
          }`}
          href='https://twitter.com/Lu_Ops_'
        >
          <BsTwitter />
        </a>
      </section>
    </div>
  )
}

export default Footer
