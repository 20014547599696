import { useContext } from 'react'
import { ThemeContext } from './ThemeContext'
import { MdWbSunny, MdDarkMode } from 'react-icons/md'

export default function ToggleTheme() {
  const theme = useContext(ThemeContext)
  const darkMode = theme.state.darkMode

  const onClick = () => {
    if (darkMode) {
      theme.dispatch({ type: 'LIGHTMODE' })
    } else {
      theme.dispatch({ type: 'DARKMODE' })
    }
  }

  return (
    <button
      className={`btn ${darkMode ? 'btn-dark' : 'btn-light'}`}
      onClick={onClick}
    >
      {darkMode ? <MdWbSunny /> : <MdDarkMode />}
    </button>
  )
}
