import { useContext } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ThemeContext } from './theme/ThemeContext'
import { PrismicProvider } from '@prismicio/react'
import { client } from './api/prismic'
import './index.css'
import Nav from './components/Nav'
import Home from './views/Home'
import Blogs from './views/Blogs'
import BlogPost from './views/BlogPost'
import Footer from './components/Footer'

import { ThemeProvider } from './theme/ThemeContext'

const App = () => {
  const theme = useContext(ThemeContext)
  const darkMode = theme.state.darkMode
  return (
    <div className={`wrapper ${darkMode ? 'bg-dark' : 'bg-light'}`}>
      <Nav />
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route path='/blogs' element={<Blogs />} />
        <Route path='/blog/:uid' element={<BlogPost />} />
      </Routes>
      <Footer />
    </div>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <PrismicProvider client={client}>
    <Router>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </Router>
  </PrismicProvider>
)
